export const navData = [
  {
    name: "酒店",
    book: [
      { name: "意宇时尚制服十", id: 0 },
      { name: "意宇时尚制服十二", id: 1 },
    ],
  },
  {
    name: "物业",
    book: [{ name: "意宇时尚制服九", id: 2 }],
  },
  {
    name: "商务定制",
    book: [
      { name: "高级职业装典藏", id: 3 },
      { name: "国际高端定制", id: 4 },
      { name: "高端商务装", id: 5 },
      { name: "高端商务装II", id: 6 },
      { name: "高端商务装III", id: 7 },
      { name: "高端商务装IV", id: 8 },
    ],
  },
  {
    name: "校服",
    book: [{ name: "意宇国际化校服", id: 9 }],
  },
  {
    name: "工程",
    book: [{ name: "国际化工程防护服II", id: 10 }],
  },
];
