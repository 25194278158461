import coverBook from "../Images/coverBooks.png";
import coverModel from "../Images/homeCover.png";
import "../Styles/home.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import HomeExample from "../Components/HomeExample";

const examples = [
  {
    name: "成功案例",
    link: "case",
    image1: require("../Images/case/schkkc.png"),
    image2: require("../Images/case/hdgtcwy.png"),
    image3: require("../Images/case/lfxzb_home.jpg"),
    image4: require("../Images/case/hbjt_home.jpg"),
  },
  {
    name: "出版书籍",
    link: "book",
    image1: require("../Images/book/意宇十二/封面.jpg"),
    image2: require("../Images/book/高端4/封面.jpg"),
    image3: require("../Images/book/意宇九（物业）/封面.jpg"),
    image4: require("../Images/book/高级典藏/封面.jpg"),
  },
];

function Home() {
  const navigate = useNavigate();
  const handleClick = () => navigate("/contact");

  return (
    <div>
      <div className="cover">
        <span style={{ "--i": 1 }}>专</span>
        <span style={{ "--i": 2 }}>业</span>
        <span style={{ "--i": 3 }}>制</span>
        <span style={{ "--i": 4 }}>服</span>
        <span style={{ "--i": 5 }}>设</span>
        <span style={{ "--i": 6 }}>计</span>
        <p>
          提供专业系统化服装设计服务，提供快捷优质的电子商务平台。拥有自身雄厚的设计师团队，拥有全国最强大的制服设计图库，以
          <br />
          <h3>"品质取胜，拒绝雷同"</h3>
        </p>
        <button onClick={handleClick}>联系我们</button>
      </div>
      <div className="coverImage">
        <div className="coverImage_item">
          <img
            src={coverBook}
            alt="cover books"
            style={{ marginLeft: "100px" }}
          />
        </div>
        <div className="coverImage_item">
          <img
            src={coverModel}
            alt="hand draw model"
            style={{ width: "400px" }}
          />
        </div>
      </div>
      <div className="intro">
        <div>
          <h2>设计师简介</h2>
          <p>
            毕业于上海东华大学服装设计系，现为中国服装协会会员，多年潜心学习，专心致力于服装设计工作，拥有深厚的设计经验，任多家上市企业形象策划顾问，成为业内广受欢迎的资深设计师。
          </p>
          <br />
          <p>
            <b>成功设计案例有：</b>
            中国南方航空公司空乘人员、四川航空公司、深圳机场、海航集团、海口美兰国际机场、广铁集团、青岛兰亭机场、川藏铁路乘务、海南工商银行、深圳农业银行、广州农村商业银行、深圳公交集团、深圳地铁制服、深圳太阳百货、深圳天虹商场、一致药店、海王星辰、威尼斯酒店、香格里拉酒店、上海经贸大厦等大型制服设计项目被采纳。
          </p>
          <p>
            <b>曾设计出版过：</b>
            《国际时尚职业装》；《国际经典制服专家》；《国际商业促销服》；《国际酒店制服经典》；《国际制服新时尚》；《桑拿服的春天》一、二册；《现代风的篇章-酒店制服》；《时尚典范商务装》；《国际化工程防护服》；《商务装四季风》；《意宇时尚制服九》；《意宇时尚制服十》；《高端商务装》；《意宇时尚制服十二》；《高端商务装II》等，受到广泛关注和赞誉。
          </p>
        </div>
      </div>
      <br />
      <br />
      {examples.map((item) => (
        <HomeExample example={item} />
      ))}
    </div>
  );
}
export default Home;
