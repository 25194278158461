import "../Styles/book.css";
import "../Styles/main.css";
import { useParams } from "react-router-dom";
import { bookData } from "../Data/BookData";
import { useNavigate } from "react-router-dom";

function Book() {
  const navigate = useNavigate();
  const handleClick = () => navigate("/contact");
  const { id } = useParams();

  return (
    <div className="book">
      <h1>{bookData[id].name}</h1>
      <p>高清精装160P</p>
      <div className="bookDisplay">
        {bookData[id].images.map((image) => (
          <img src={image} alt="book image" />
        ))}
      </div>
      <div className="center">
        <button
          onClick={handleClick}
          style={{ width: "auto", paddingInline: "15px" }}
        >
          购买书籍请联系我们
        </button>
      </div>
    </div>
  );
}
export default Book;
