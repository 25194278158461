import "../Styles/contact.css";

function Contact() {
  return (
    <div className="contactContainer">
      <h2>联系方式</h2>
      <p>地址：广东省深圳市罗湖区人民北路芳邻大厦4A4</p>
      <p>座机：0755-82355205</p>
      <p>电话：13823355146； 13823754810</p>
      <p>邮箱：490826266@qq.com</p>
    </div>
  );
}
export default Contact;
