import "../Styles/design.css";

function Design() {
  return (
    <div className="design">
      <h3>真人效果图</h3>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        <img
          src={require("../Images/book/工程/045_02.jpg")}
          width="300px"
          alt="zhenren"
        />
        <img
          src={require("../Images/book/校服/137_02.jpg")}
          width="300px"
          alt="zhenren"
        />
      </div>
      <br />
      <div className="inline">
        <div>
          <h3>平面工艺图</h3>
          <img src={require("../Images/design/gongyi.jpg")} alt="shouhui" />
        </div>
        <div>
          <h3>手绘设计图</h3>
          <img src={require("../Images/design/shouhui.jpg")} alt="shouhui" />
        </div>
      </div>
      <br />
      <h3>
        系列配套设计：可包含真人效果图，设计介绍，简介，工艺图等。根据需求定制专属私人设计套装
      </h3>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        <img
          src={require("../Images/design/chengtao1.jpg")}
          width="400px"
          alt="shouhui"
        />
        <img
          src={require("../Images/design/chengtao2.jpg")}
          width="400px"
          alt="shouhui"
        />
      </div>
    </div>
  );
}
export default Design;
