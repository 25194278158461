function HomeExample(info) {
  const { example } = info;
  return (
    <div className="showCase">
      <div className="inLine">
        <h2>{example.name}</h2>
        <a href={"/" + example.link}>查看全部</a>
      </div>
      <div className="images">
        <img src={example.image1} alt="case image" />
        <img src={example.image2} alt="case image" />
        <img src={example.image3} alt="case image" />
        <img src={example.image4} alt="case image" />
      </div>
    </div>
  );
}
export default HomeExample;
