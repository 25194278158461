import { useRef, useState, React } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../Styles/main.css";
import { navData } from "../Data/NavData";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

// book menu

function Navbar() {
  // const navigate = useNavigate();
  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
    menuRef.current.classList.replace("closed", "menu");
  };

  const menuRef = useRef();
  const closeSub = () => {
    navRef.current.classList.remove("responsive_nav");
    menuRef.current.classList.replace("menu", "closed");
  };
  const openSub = () => {
    menuRef.current.classList.replace("closed", "menu");
  };
  return (
    <header>
      <h1>YIYU意宇</h1>
      <div style={{ display: "flex" }}>
        <nav ref={navRef}>
          <ul>
            <li>
              <a href="/">主页</a>
            </li>
            <li>
              <a href="/design">委托设计</a>
            </li>
            <li>
              <a href="/case">成功案例</a>
            </li>
            <li onMouseEnter={openSub}>
              <a>出版书籍</a>
              <div className="menu" ref={menuRef}>
                {navData.map((books) => (
                  <div>
                    <h3>{books.name}</h3>
                    {books.book.map((item) => (
                      <Link
                        to={`/book/${item.id}`}
                        state={{ data: item }}
                        onClick={closeSub}
                      >
                        <p>{item.name}</p>
                      </Link>
                    ))}
                  </div>
                ))}
              </div>
            </li>
            <li>
              <a href="/contact">联系我们</a>
            </li>
          </ul>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes style={{ width: "50px", height: "50px" }} />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars style={{ width: "50px", height: "50px" }} />
        </button>
      </div>
    </header>
  );
}
export default Navbar;
