export const bookData = [
  {
    name: "意宇时尚制服十",
    images: [
      require("../Images/book/意宇十/封面.jpg"),
      require("../Images/book/意宇十/01.jpg"),
      require("../Images/book/意宇十/02.jpg"),
      require("../Images/book/意宇十/03.jpg"),
      require("../Images/book/意宇十/04.jpg"),
      require("../Images/book/意宇十/05.jpg"),
      require("../Images/book/意宇十/06.jpg"),
      require("../Images/book/意宇十/07.jpg"),
      require("../Images/book/意宇十/08.jpg"),
      require("../Images/book/意宇十/09.jpg"),
      require("../Images/book/意宇十/10.jpg"),
    ],
  },
  {
    name: "意宇时尚制服十二",
    images: [
      require("../Images/book/意宇十二/封面.jpg"),
      require("../Images/book/意宇十二/01.jpg"),
      require("../Images/book/意宇十二/02.jpg"),
      require("../Images/book/意宇十二/03.jpg"),
      require("../Images/book/意宇十二/04.jpg"),
      require("../Images/book/意宇十二/05.jpg"),
      require("../Images/book/意宇十二/06.jpg"),
      require("../Images/book/意宇十二/07.jpg"),
    ],
  },
  {
    name: "意宇时尚制服九",
    images: [
      require("../Images/book/意宇九（物业）/封面.jpg"),
      require("../Images/book/意宇九（物业）/01.jpg"),
      require("../Images/book/意宇九（物业）/02.jpg"),
      require("../Images/book/意宇九（物业）/03.jpg"),
      require("../Images/book/意宇九（物业）/04.jpg"),
      require("../Images/book/意宇九（物业）/05.jpg"),
      require("../Images/book/意宇九（物业）/06.jpg"),
      require("../Images/book/意宇九（物业）/07.jpg"),
    ],
  },
  {
    name: "高级职业装典藏",
    images: [
      require("../Images/book/高级典藏/封面.jpg"),
      require("../Images/book/高级典藏/01.jpg"),
      require("../Images/book/高级典藏/02.jpg"),
      require("../Images/book/高级典藏/03.jpg"),
      require("../Images/book/高级典藏/04.jpg"),
      require("../Images/book/高级典藏/05.jpg"),
      require("../Images/book/高级典藏/06.jpg"),
    ],
  },
  {
    name: "国际高端定制",
    images: [
      require("../Images/book/国际高端定制/封面.jpg"),
      require("../Images/book/国际高端定制/01.jpg"),
      require("../Images/book/国际高端定制/02.jpg"),
      require("../Images/book/国际高端定制/03.jpg"),
      require("../Images/book/国际高端定制/04.jpg"),
      require("../Images/book/国际高端定制/05.jpg"),
      require("../Images/book/国际高端定制/06.jpg"),
    ],
  },
  {
    name: "高端商务装（己售完)",
    images: [
      require("../Images/book/高端商务装（己售完）/封面.jpg"),
      require("../Images/book/高端商务装（己售完）/01.jpg"),
      require("../Images/book/高端商务装（己售完）/02.jpg"),
      require("../Images/book/高端商务装（己售完）/03.jpg"),
      require("../Images/book/高端商务装（己售完）/04.jpg"),
      require("../Images/book/高端商务装（己售完）/05.jpg"),
      require("../Images/book/高端商务装（己售完）/06.jpg"),
      require("../Images/book/高端商务装（己售完）/07.jpg"),
      require("../Images/book/高端商务装（己售完）/08.jpg"),
      require("../Images/book/高端商务装（己售完）/09.jpg"),
    ],
  },
  {
    name: "高端商务装II",
    images: [
      require("../Images/book/高端2/封面.jpg"),
      require("../Images/book/高端2/01.jpg"),
      require("../Images/book/高端2/02.jpg"),
      require("../Images/book/高端2/03.jpg"),
      require("../Images/book/高端2/04.jpg"),
      require("../Images/book/高端2/05.jpg"),
      require("../Images/book/高端2/06.jpg"),
      require("../Images/book/高端2/07.jpg"),
    ],
  },
  {
    name: "高端商务装III",
    images: [
      require("../Images/book/高端3/封面.jpg"),
      require("../Images/book/高端3/01.jpg"),
      require("../Images/book/高端3/02.jpg"),
      require("../Images/book/高端3/03.jpg"),
      require("../Images/book/高端3/04.jpg"),
      require("../Images/book/高端3/05.jpg"),
      require("../Images/book/高端3/06.jpg"),
      require("../Images/book/高端3/07.jpg"),
      require("../Images/book/高端3/08.jpg"),
    ],
  },
  {
    name: "高端商务装IV",
    images: [
      require("../Images/book/高端4/封面.jpg"),
      require("../Images/book/高端4/01.jpg"),
      require("../Images/book/高端4/02.jpg"),
      require("../Images/book/高端4/03.jpg"),
      require("../Images/book/高端4/04.jpg"),
      require("../Images/book/高端4/05.jpg"),
      require("../Images/book/高端4/06.jpg"),
      require("../Images/book/高端4/07.jpg"),
      require("../Images/book/高端4/08.jpg"),
      require("../Images/book/高端4/09.jpg"),
      require("../Images/book/高端4/10.jpg"),
      require("../Images/book/高端4/11.jpg"),
    ],
  },
  {
    name: "意宇国际化校服",
    images: [
      require("../Images/book/校服/019_01.jpg"),
      require("../Images/book/校服/019_02.jpg"),
      require("../Images/book/校服/063_01.jpg"),
      require("../Images/book/校服/063_02.jpg"),
      require("../Images/book/校服/071_01.jpg"),
      require("../Images/book/校服/137_01.jpg"),
      require("../Images/book/校服/137_02.jpg"),
    ],
  },
  {
    name: "国际化工程防护服II",
    images: [
      require("../Images/book/工程/005_01.jpg"),
      require("../Images/book/工程/005_02.jpg"),
      require("../Images/book/工程/045_01.jpg"),
      require("../Images/book/工程/045_02.jpg"),
      require("../Images/book/工程/121_01.jpg"),
      require("../Images/book/工程/121_02.jpg"),
    ],
  },
];
