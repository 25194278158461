export const caseData = [
  {
    title: "四川航空空乘",
    cover: require("../Images/case/schkkc.png"),
  },
  {
    title: "哈大高铁乘务员",
    cover: require("../Images/case/hdgtcwy.png"),
  },

  {
    title: "河北交通",
    cover: require("../Images/case/hbjt.jpg"),
  },
  {
    title: "老凤祥珠宝",
    cover: require("../Images/case/lfxzb.jpg"),
  },
  {
    title: "数字龙华、绽放未来--校服",
    cover: require("../Images/case/szlh.jpg"),
  },
  {
    title: "万科物业保安",
    cover: require("../Images/case/wkwy.jpg"),
  },
  {
    title: "白云机场安检及廊桥工程服",
    cover: require("../Images/case/byjc.jpg"),
  },
  {
    title: "金伯利钻石",
    cover: require("../Images/case/jblzsdy.png"),
  },
  {
    title: "六福珠宝店员",
    cover: require("../Images/case/lfzbdy.png"),
  },
  {
    title: "湛江机场窗口服务",
    cover: require("../Images/case/zjjcckfw.png"),
  },
  {
    title: "西藏航空乘务员",
    cover: require("../Images/case/xzhkcwy.jpg"),
  },
  {
    title: "广铁乘务员",
    cover: require("../Images/case/gtcwy.jpg"),
  },
  {
    title: "一带一路 肯尼亚航空制服",
    cover: require("../Images/case/knyhk.jpg"),
  },
];
