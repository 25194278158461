import { caseData } from "../Data/CaseData";
import "../Styles/case.css";

function Case() {
  return (
    <div className="cases">
      <p>
        多年的努力下，促成了丰富的成功案例：四川航空公司空乘人员、深圳市机场地勤人员、湛江机场地勤人员、哈大高铁乘务员、重渝铁路乘务员、海南工商银行、深圳农业银行、广州农村商业银行、深圳公交集团、南昌地铁、大连地铁、周大福珠宝、六福珠宝、老凤祥珠宝、金伯利钻石等。。。
      </p>
      <br />
      <h2>部分成功案例展示</h2>
      <div className="caseMain">
        {caseData.map((item) => (
          <div>
            <h3>{item.title}</h3>
            <img src={item.cover} alt="cover" />
          </div>
        ))}
      </div>
    </div>
  );
}
export default Case;
