import Navbar from "./Components/Navbar";
import React from "react";
import "./App.css";
import Home from "./Pages/Home";
import Case from "./Pages/Case";
import Book from "./Pages/Book";
import Contact from "./Pages/Contact";
import Design from "./Pages/Design";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <React.Fragment>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/book" element={<Home />} />
        <Route path="/design" element={<Design />} />
        <Route path="/case" element={<Case />} />
        <Route path="/book/:id" element={<Book />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <div className="rooter">
        <p>Copyright @YiYu</p>
      </div>
    </React.Fragment>
  );
}

export default App;
